import styled from "styled-components/macro"
import { layout, palette, pxToRem } from "../../../utils/style-utils"

export const formGuttersW = layout.pageGutterW
export const FormFieldset = styled.fieldset`
  padding: ${pxToRem(formGuttersW)} ${pxToRem(formGuttersW)} ${pxToRem(formGuttersW / 2)};
  & > * {
    margin-bottom: ${pxToRem(formGuttersW / 2)};
  }
  & > p {
    font-size: ${pxToRem(12)};
    color: ${palette.gray1};
  }
`
