import { Field, Form, Formik, FormikErrors } from "formik"
import React from "react"
import { EntryDetailsQuery_entry } from "../../../../__generated__/EntryDetailsQuery"
import { extractValidationError } from "../../../../common/apiErrors"
import { TPoolRouteProps } from "../../../../routes.d"
import LocalKey from "../../../Base/LocalKey"
import AnalyticScreen from "../../../components/AnalyticsScreen"
import { ButtonSmallBase } from "../../../components/Button"
import Code from "../../../components/Code"
import { errorClassFor, FormButtonSpinnerBase, FormErrors, FormSuccessStatus } from "../../../components/Form"
import CloseSvg from "../../../components/icons/Close"
import PencilSvg from "../../../components/icons/Pencil"
import LoadingView from "../../../components/LoadingView"
import Modal, { ModalWrapper } from "../../../components/Modal"
import PageLayout from "../../../shared/atoms/Page/PageLayout"
import { TUpsertEntryMutation } from "../../PoolSetupPages/PoolSetupTypes.d"
import {
  MyEntryAvatar,
  MyEntryAvatarContainer,
  MyEntryAvatarFieldset,
  MyEntryEditBtn,
  MyEntryField,
  MyEntryForm,
  MyEntryInputContainer,
  MyEntryLeftCol,
  MyEntryLegend,
  MyEntryRightCol,
} from "../styles/PoolMyEntry.styles"
import { FormFieldset } from "../styles/PoolShared.styles"
import Link from "../../../components/Link"
import constants from "../../../../common/constants"

const EntryAvatarForm = React.lazy(() => import("./EntryAvatarForm"))

interface IProps {
  periodId: string
  entry: EntryDetailsQuery_entry
  isChallengePool: boolean
  upsertEntryMutation: TUpsertEntryMutation
  hasManagerRole: boolean
  isIosBrowser: boolean
  isCbsAppWebview: boolean
  userAgent: string
  cookies: string[]
}

class PoolMyEntry extends React.PureComponent<IProps> {
  public getInitialValues = () => {
    // const segment = getSegment(this.props.pool);
    // const currentPoolSettings = Object.assign({}, segment.poolSettings, this.props.pool.poolSettings);
    // // initialize roundBonuses
    // if (currentPoolSettings.hasOwnProperty("roundBonuses")) {
    //   const roundBonuses = currentPoolSettings.roundBonuses;
    //   segment.periods.edges.forEach((e, i) => {
    //     if (roundBonuses.length <= i) {
    //       roundBonuses.push(i + 1);
    //     }
    //   });
    // }
    // // console.log(`getInitialValues:`)
    // // console.dir(currentPoolSettings)
    // return currentPoolSettings;
    const { entry } = this.props
    return {
      name: entry.name,
      email: entry.userInfo.email,
      hideEmail: entry.hideEmail,
    }
  }

  public onSubmit = async (values, actions) => {
    // const {currentStep, pool} = this.props;
    // console.log(`"onSubmit" ${currentStep}`);
    // console.dir(this.props);
    // console.dir(values);
    // console.dir(actions);
    // const stepMapping = stepMappings.find((m) => m.path === currentStep);
    // const field = stepMapping && stepMapping.field;
    // if (field) {
    //   await setValueSubmit(this.props, field, values[field]);
    // } else {
    //   console.warn(`unknown submit step: ${currentStep}`);
    // }
    const { upsertEntryMutation, entry, periodId } = this.props
    const variables = Object.assign(
      {
        entryId: entry.id,
        poolId: entry.pool?.id,
        periodId,
      },
      values,
    )
    return upsertEntryMutation({ variables })
      .then((_res) => {
        // console.log('res')
        // console.dir(res)
        actions.setStatus(FormSuccessStatus)
        actions.setSubmitting(false)
        setTimeout(() => {
          actions.resetForm()
          // })
        }, 900)
      })
      .catch((err) => {
        // console.log('err')
        // console.dir(err)
        const apiErrors = extractValidationError(err)
        actions.setErrors(apiErrors.errors)
        actions.setSubmitting(false)
      })
  }

  public validate = async (values) => {
    // console.log("validate");
    // console.dir(values);
    const errors: FormikErrors<any> = {}
    if (!values.name) {
      errors.name = "Name is required"
    }
    return errors
  }

  public render() {
    const props = this.props
    const { entry, periodId, isChallengePool, hasManagerRole, isCbsAppWebview } = props
    const { upsertEntryMutation, ...others } = props
    // console.log(`PoolSetupStepForm props`);
    // console.dir(this.props);
    return (
      <PageLayout>
        <AnalyticScreen feature="options" subfeature="personal" title={`Settings - Personal`} />
        <MyEntryForm>
          <Formik initialValues={this.getInitialValues()} onSubmit={this.onSubmit} validate={this.validate} enableReinitialize={true}>
            {(formikBag) => {
              // console.log("formikBag");
              // console.dir(formikBag);
              const { isSubmitting, status, isValid, setStatus, resetForm } = formikBag
              const toggleStatus = () => {
                if (status) {
                  resetForm()
                } else {
                  setStatus("enabled")
                }
              }
              return (
                <FormFieldset as={Form}>
                  <MyEntryLegend>Your Info</MyEntryLegend>
                  <MyEntryInputContainer className={`enabled--${!!status}`}>
                    <MyEntryLeftCol as="label" htmlFor="name">
                      Name
                    </MyEntryLeftCol>
                    <MyEntryRightCol>
                      <MyEntryField
                        as={Field}
                        className={errorClassFor(formikBag, "name")}
                        type="text"
                        name="name"
                        autoComplete="off"
                        id="name"
                        disabled={!status}
                      />
                      <MyEntryEditBtn as="button" type="button" onClick={toggleStatus}>
                        {!!status ? <CloseSvg color="#000000" /> : <PencilSvg />}
                      </MyEntryEditBtn>
                    </MyEntryRightCol>
                  </MyEntryInputContainer>
                  {!isChallengePool && (
                    <MyEntryInputContainer>
                      <MyEntryLeftCol as="label" htmlFor="email">
                        Email
                      </MyEntryLeftCol>
                      <MyEntryRightCol>
                        <MyEntryField
                          as={Field}
                          className={errorClassFor(formikBag, "email")}
                          type="email"
                          name="email"
                          autoComplete="email"
                          id="email"
                          disabled={true}
                        />
                        <MyEntryEditBtn as={Link} to={`https://www${constants.IS_PROD ? "" : ".qa"}.cbssports.com/settings/account`} external>
                          <PencilSvg />
                        </MyEntryEditBtn>
                      </MyEntryRightCol>
                    </MyEntryInputContainer>
                  )}
                  {!isChallengePool && (
                    <MyEntryInputContainer as="label">
                      <MyEntryLeftCol>
                        <Field type="checkbox" name="hideEmail" checked={formikBag.values.hideEmail} />
                      </MyEntryLeftCol>
                      <MyEntryRightCol>
                        <span>Do not show my email address to other pool members</span>
                      </MyEntryRightCol>
                    </MyEntryInputContainer>
                  )}

                  <FormErrors {...formikBag} />
                  <FormButtonSpinnerBase
                    as={ButtonSmallBase}
                    success="Saved!"
                    type="submit"
                    inert="Save Changes"
                    status={status}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                  />
                  {(hasManagerRole && isChallengePool && <Code style={{ maxWidth: "40rem" }}>{JSON.stringify(others, null, 2)}</Code>) || null}
                </FormFieldset>
              )
            }}
          </Formik>
          <LocalKey keyName="modals">
            {({ value, update }) => {
              const close = (): void => {
                update(null)
              }
              return (
                <MyEntryAvatarFieldset>
                  <MyEntryLegend>Your Profile Pic</MyEntryLegend>
                  <MyEntryAvatarContainer>
                    <MyEntryAvatar size={60} as="img" src={(entry && entry.avatarUrl) || `//placehold.it/150x150`} alt="Entry Avatar" />
                  </MyEntryAvatarContainer>
                  <ButtonSmallBase as="button" type="button" onClick={() => update("EntryAvatarModal")}>
                    Change Image
                  </ButtonSmallBase>
                  <Modal isOpen={value === "EntryAvatarModal"} onBackgroundClick={close} style={{ maxWidth: "800px" }}>
                    <ModalWrapper header="Upload a new Avatar" closeBtnAction={close}>
                      <React.Suspense fallback={<LoadingView />}>
                        <EntryAvatarForm
                          entry={entry}
                          close={close}
                          periodId={periodId}
                          upsertEntryMutation={upsertEntryMutation}
                          isCbsAppWebview={isCbsAppWebview}
                        />
                      </React.Suspense>
                    </ModalWrapper>
                  </Modal>
                </MyEntryAvatarFieldset>
              )
            }}
          </LocalKey>
        </MyEntryForm>
      </PageLayout>
    )
  }
}

function PoolMyEntryWrapper(props: TPoolRouteProps) {
  const { poolData } = props
  const { detailedEntry, periodId, isChallengePool, hasManagerRole, isIosBrowser, isCbsAppWebview, upsertEntryMutation } = poolData
  const userAgent = (typeof navigator !== "undefined" && navigator.userAgent) || ""
  const cookies = (typeof document !== "undefined" && document.cookie.split(";")) || []
  if (detailedEntry) {
    return (
      <PoolMyEntry
        entry={detailedEntry}
        periodId={periodId}
        isChallengePool={isChallengePool}
        upsertEntryMutation={upsertEntryMutation}
        hasManagerRole={hasManagerRole}
        isIosBrowser={isIosBrowser}
        isCbsAppWebview={isCbsAppWebview}
        userAgent={userAgent}
        cookies={cookies}
      />
    )
  } else {
    return <LoadingView />
  }
}

export default PoolMyEntryWrapper
